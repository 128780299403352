/* tslint:disable */
/* eslint-disable */
/**
 * Mousai Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mix-dev@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The \'type\' of music experience categorizing what is in the content itself.
 * @export
 * @enum {string}
 */

export enum ExperienceType {
    ANNOUNCEMENT = 'ANNOUNCEMENT',
    COMMENTARY = 'COMMENTARY',
    VOICE_COMMENT = 'VOICE_COMMENT',
    ARTIST_QUEUE_INTRO = 'ARTIST_QUEUE_INTRO'
}


