/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: mix-dev@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CatalogStage } from '../models';
// @ts-ignore
import { InterludeAssociationOutputList } from '../models';
// @ts-ignore
import { InterludeAssociations } from '../models';
// @ts-ignore
import { ListAssociationsInput } from '../models';
// @ts-ignore
import { ListAssociationsOutput } from '../models';
// @ts-ignore
import { SimpleError } from '../models';
/**
 * AssociationsApi - axios parameter creator
 * @export
 */
export const AssociationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete all entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterludeAssociations: async (stage: CatalogStage, interludeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('deleteInterludeAssociations', 'stage', stage)
            // verify required parameter 'interludeId' is not null or undefined
            assertParamExists('deleteInterludeAssociations', 'interludeId', interludeId)
            const localVarPath = `/interludes/{interludeId}/associations`
                .replace(`{${"interludeId"}}`, encodeURIComponent(String(interludeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterludeAssociations: async (stage: CatalogStage, interludeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getInterludeAssociations', 'stage', stage)
            // verify required parameter 'interludeId' is not null or undefined
            assertParamExists('getInterludeAssociations', 'interludeId', interludeId)
            const localVarPath = `/interludes/{interludeId}/associations`
                .replace(`{${"interludeId"}}`, encodeURIComponent(String(interludeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates associations in the catalog
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {ListAssociationsInput} [listAssociationsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssociations: async (stage: CatalogStage, maxResults?: number, nextToken?: string, listAssociationsInput?: ListAssociationsInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('listAssociations', 'stage', stage)
            const localVarPath = `/associations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listAssociationsInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {InterludeAssociations} [interludeAssociations] Entity associations for the interlude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterludeAssociations: async (stage: CatalogStage, interludeId: string, interludeAssociations?: InterludeAssociations, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('updateInterludeAssociations', 'stage', stage)
            // verify required parameter 'interludeId' is not null or undefined
            assertParamExists('updateInterludeAssociations', 'interludeId', interludeId)
            const localVarPath = `/interludes/{interludeId}/associations`
                .replace(`{${"interludeId"}}`, encodeURIComponent(String(interludeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interludeAssociations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssociationsApi - functional programming interface
 * @export
 */
export const AssociationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssociationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete all entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInterludeAssociations(stage: CatalogStage, interludeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInterludeAssociations(stage, interludeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterludeAssociations(stage: CatalogStage, interludeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterludeAssociationOutputList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterludeAssociations(stage, interludeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enumerates associations in the catalog
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {ListAssociationsInput} [listAssociationsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssociations(stage: CatalogStage, maxResults?: number, nextToken?: string, listAssociationsInput?: ListAssociationsInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAssociationsOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssociations(stage, maxResults, nextToken, listAssociationsInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {InterludeAssociations} [interludeAssociations] Entity associations for the interlude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInterludeAssociations(stage: CatalogStage, interludeId: string, interludeAssociations?: InterludeAssociations, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInterludeAssociations(stage, interludeId, interludeAssociations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssociationsApi - factory interface
 * @export
 */
export const AssociationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssociationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete all entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterludeAssociations(stage: CatalogStage, interludeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInterludeAssociations(stage, interludeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterludeAssociations(stage: CatalogStage, interludeId: string, options?: any): AxiosPromise<InterludeAssociationOutputList> {
            return localVarFp.getInterludeAssociations(stage, interludeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates associations in the catalog
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {ListAssociationsInput} [listAssociationsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssociations(stage: CatalogStage, maxResults?: number, nextToken?: string, listAssociationsInput?: ListAssociationsInput, options?: any): AxiosPromise<ListAssociationsOutput> {
            return localVarFp.listAssociations(stage, maxResults, nextToken, listAssociationsInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the entity association rules for a given interlude
         * @param {CatalogStage} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {InterludeAssociations} [interludeAssociations] Entity associations for the interlude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterludeAssociations(stage: CatalogStage, interludeId: string, interludeAssociations?: InterludeAssociations, options?: any): AxiosPromise<void> {
            return localVarFp.updateInterludeAssociations(stage, interludeId, interludeAssociations, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssociationsApi - object-oriented interface
 * @export
 * @class AssociationsApi
 * @extends {BaseAPI}
 */
export class AssociationsApi extends BaseAPI {
    /**
     * 
     * @summary Delete all entity association rules for a given interlude
     * @param {CatalogStage} stage The stage in the catalog where the operation should take place
     * @param {string} interludeId The ID of the interlude to update or delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssociationsApi
     */
    public deleteInterludeAssociations(stage: CatalogStage, interludeId: string, options?: AxiosRequestConfig) {
        return AssociationsApiFp(this.configuration).deleteInterludeAssociations(stage, interludeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the entity association rules for a given interlude
     * @param {CatalogStage} stage The stage in the catalog where the operation should take place
     * @param {string} interludeId The ID of the interlude to update or delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssociationsApi
     */
    public getInterludeAssociations(stage: CatalogStage, interludeId: string, options?: AxiosRequestConfig) {
        return AssociationsApiFp(this.configuration).getInterludeAssociations(stage, interludeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates associations in the catalog
     * @param {CatalogStage} stage The stage in the catalog where the operation should take place
     * @param {number} [maxResults] The maximum number of results to return for paginated queries
     * @param {string} [nextToken] The next page token to retrieve from a paginated query
     * @param {ListAssociationsInput} [listAssociationsInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssociationsApi
     */
    public listAssociations(stage: CatalogStage, maxResults?: number, nextToken?: string, listAssociationsInput?: ListAssociationsInput, options?: AxiosRequestConfig) {
        return AssociationsApiFp(this.configuration).listAssociations(stage, maxResults, nextToken, listAssociationsInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the entity association rules for a given interlude
     * @param {CatalogStage} stage The stage in the catalog where the operation should take place
     * @param {string} interludeId The ID of the interlude to update or delete
     * @param {InterludeAssociations} [interludeAssociations] Entity associations for the interlude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssociationsApi
     */
    public updateInterludeAssociations(stage: CatalogStage, interludeId: string, interludeAssociations?: InterludeAssociations, options?: AxiosRequestConfig) {
        return AssociationsApiFp(this.configuration).updateInterludeAssociations(stage, interludeId, interludeAssociations, options).then((request) => request(this.axios, this.basePath));
    }
}
